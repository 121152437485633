<script setup lang="ts">
const chatStore = useChatStore();
const { locale } = useI18n();
</script>

<template>
  <p class="absolute bottom-2.5 inset-x-2.5 @lg:inset-x-5 text-sm text-neutral-5 h-5">
    {{
      chatStore.chats[chatStore.roomName]?.isTyping?.reduce((acc, curr, index, arr) => {
        if (arr.length === 1) {
          return locale === "en" ? `${curr} is typing...` : `${curr} schreibt...`;
        }
        if (index === 0) {
          return `${curr}, `;
        }
        if (index === arr.length - 1) {
          return locale === "en" ? `${acc} and ${curr} are typing...` : `${acc} und ${curr} schreiben...`;
        }
        return `${acc}, ${curr}`;
      }, "")
    }}
  </p>
</template>
