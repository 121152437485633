type EmitFn = {
  (event: "isTyping", value: boolean): void;
};

export default function useIsTyping(emits: EmitFn) {
  const isTyping = ref(false);
  const timeoutId = ref<NodeJS.Timeout | undefined>(undefined);
  return (event: KeyboardEvent) => {
    // ignore non-character keys - especially enter (which sends the message)
    if (event.key.length > 1) {
      if (timeoutId.value) clearTimeout(timeoutId.value);
      emits("isTyping", false);
      isTyping.value = false;
      return;
    }
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
      if (!isTyping.value) {
        emits("isTyping", true);
        isTyping.value = true;
      }
    }
    timeoutId.value = setTimeout(() => {
      isTyping.value = false;
      emits("isTyping", false);
      clearTimeout(timeoutId.value);
    }, 1000);
  };
}
