import dayjs from "dayjs";

import type { Comment } from "~/types";

export default function useCommentOptions(comment: Comment) {
  const { username } = useSessionInfo();
  return {
    isEditable: computed(() => {
      const createdAtDate = dayjs(comment.createdAt);
      const hoursSinceCreation = dayjs().diff(createdAtDate, "hour");
      return comment.createdByUser.username === username.value && hoursSinceCreation <= 1;
    }),
    isDeletable: computed(() => {
      const createdAtDate = dayjs(comment.createdAt);
      const hoursSinceCreation = dayjs().diff(createdAtDate, "day");
      return comment.createdByUser.username === username.value && hoursSinceCreation <= 3;
    })
  };
}
