<script lang="ts" setup>
defineProps<{
  title?: string;
  class?: string;
}>();
const chatStore = useChatStore();
const userProfileStore = useUserProfileStore();

watch(
  () => [chatStore.roomName, chatStore.matrixIsReady],
  ([newRoomName, newMatrixIsReady]) => {
    if (newRoomName && newMatrixIsReady) {
      chatStore.switchToRoom(newRoomName as string);
    }
  },
  { immediate: true }
);

const showThirdPanel = computed(() => chatStore.chats[chatStore.roomName]?.showThirdPanel);

const target = ref();
usePageNavigation(target, "chat");
</script>

<template>
  <section
    id="chat"
    ref="target"
    :class="
      cn(
        'xl:basis-0 xl:grow h-full flex relative',
        'shrink-0 @container',
        'max-xl:snap-start',
        {
          '2xl:grow-[2]': showThirdPanel
        },
        $props.class
      )
    "
  >
    <section
      :class="
        cn('basis-0 grow h-full flex flex-col overflow-hidden', {
          'max-2xl:hidden': showThirdPanel
        })
      "
    >
      <header
        class="flex shrink-0 h-12 pl-2.5 @lg:pl-5 pr-2 items-center justify-between border-b gap-2 bg-neutral-0"
      >
        <h2 class="h2">{{ title }}</h2>
        <ChatRoomParticipants v-if="chatStore.participants" :participants="chatStore.participants" :title />
      </header>
      <ClientOnly>
        <ChatRoomChat v-if="chatStore.roomIsReady" />
      </ClientOnly>
    </section>

    <section
      v-if="showThirdPanel"
      class="basis-0 grow h-full flex flex-col 2xl:border-l border-neutral-4 dark:border-neutral-3 overflow-hidden"
    >
      <UserProfile v-if="userProfileStore.username" :key="userProfileStore.username" />
      <template v-else>
        <header class="flex shrink-0 h-12 pl-2.5 @lg:pl-5 pr-2.5 items-center border-b gap-2 bg-neutral-0">
          <h2 class="h2 grow">{{ $t("channel.thread.title") }}</h2>
          <BasicButton
            :icon="ICONS.cancel"
            tooltip="channel.thread.close"
            side="left"
            @click="chatStore.removeThread"
          />
        </header>
        <ClientOnly>
          <ChatRoomChat v-if="chatStore.threadIsReady" isThread />
        </ClientOnly>
      </template>
    </section>
  </section>
</template>
