<script lang="ts" setup>
import { generateHTML } from "@tiptap/html";

import Editor from "../Editor/index.vue";
import Menue from "./Menue/index.vue";
import Reactions from "./Reactions/index.vue";
import ThreadMenue from "./ThreadMenue.vue";
import type { Comment } from "~/types";
import TIP_TAP_EXTENSIONS from "~/utils/tipTap";

const { comment, isThreadComment } = defineProps<{
  comment: Comment;
  isThreadComment?: boolean;
  isThread?: boolean;
}>();

const chatStore = useChatStore();

const { t } = useI18n();
const userProfileStore = useUserProfileStore();

const htmlContent = computed(() => {
  if (comment.deleted) return t("channel.deletedCommentMessage");
  if (comment.blocked) return t("channel.blockedCommentMessage");
  try {
    return generateHTML(JSON.parse(comment.content || ""), TIP_TAP_EXTENSIONS);
  } catch (error) {
    console.error("Error parsing JSON", error);
    return {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [{ type: "text", text: "" }]
        }
      ]
    };
  }
});
const editComment = ref(false);
const editor = ref();

function onSend(content: string) {
  editComment.value = false;
  chatStore.updateMessage(comment.id, content);
}
function onCancel() {
  editComment.value = false;
}

const commentDisabled = computed(() => comment.blocked || comment.deleted);
const showUser = computed(() => !comment.hideUser || isThreadComment);
</script>

<template>
  <li
    class="flex gap-2 relative transition hover:bg-neutral-0 focus-within:bg-neutral-0 p-2 rounded-lg group"
  >
    <Avatar
      v-if="showUser"
      :username="comment.createdByUser.username"
      size="lg"
      @click="userProfileStore.setUser(comment.createdByUser.username)"
    />
    <Date
      v-else
      :date="comment.createdAt"
      time
      reduced
      class="w-[36px] shrink-0 text-xs text-neutral-4 relative top-[5px]"
    />

    <div class="grow flex flex-col gap-1 max-w-2xl w-full overflow-hidden">
      <div v-if="showUser" class="flex gap-3">
        <div class="flex items-baseline gap-3">
          <h3 class="text-base font-semibold">{{ comment.createdByUser.username }}</h3>
          <p class="small text-neutral-5"><Date :date="comment.createdAt" :time="!isThreadComment" /></p>
        </div>
      </div>
      <div
        v-if="!editComment"
        :class="
          cn('content font-serif', {
            'text-neutral-5': commentDisabled
          })
        "
        v-html="htmlContent"
      />
      <div v-else class="mt-1 flex flex-col gap-2">
        <Editor
          v-if="comment.content"
          ref="editor"
          :content="comment.content"
          :sendOnEnter="false"
          @send="onSend"
          @cancel="onCancel"
        />
      </div>

      <p
        v-if="!commentDisabled && comment.updatedAt && comment.createdAt !== comment.updatedAt"
        class="w-max text-sm text-neutral-5"
      >
        (bearbeitet)
      </p>

      <Menue
        v-if="!commentDisabled"
        ref="menueRef"
        :comment
        :showUser
        :isThread
        @editComment="editComment = !editComment"
      />
      <Reactions v-if="!commentDisabled" :comment />
      <ThreadMenue v-if="!commentDisabled && !isThread" :comment />
    </div>
  </li>
</template>
