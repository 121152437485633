<script lang="ts" setup>
import Typing from "./Typing.vue";
import Warning from "./Warning.vue";
import useGroupComments from "./useGroupComments";
import useScrollPosition from "./useScrollPosition";

const { isThread } = defineProps<{
  isThread?: boolean;
  class?: string;
}>();

const chatStore = useChatStore();
const groupedComments = useGroupComments(isThread);
const scrollArea = ref<HTMLElement | null>(null);

useScrollPosition(scrollArea, isThread);

function sendComment(content: string) {
  if (isThread) {
    chatStore.sendThreadMessage(content);
  } else {
    chatStore.sendMessage(content);
  }
  nextTick(() => {
    scrollArea.value?.scrollTo({ top: scrollArea.value.scrollHeight, behavior: "smooth" });
  });
}

useScroll(scrollArea, {
  onStop: () => {
    chatStore.setChatScrollTop(isThread, scrollArea.value?.scrollTop || 0);
  }
});
</script>

<template>
  <section :class="cn('relative grow w-full flex flex-col overflow-hidden', $props.class)">
    <div ref="scrollArea" class="relative scroll-area grow overflow-hidden">
      <InfiniteScroller
        direction="up"
        :elementsLength="chatStore.getComments(isThread).length"
        @loadMore="chatStore.fetchNextPage"
      >
        <ul class="relative px-1 @lg:px-3 pt-2 pb-10 flex flex-col gap-5">
          <ChatRoomComment
            v-if="chatStore.getThreadId"
            :comment="chatStore.getThreadRootComment!"
            isThreadComment
            isThread
          />
          <ChatRoomDay
            v-for="[dateString, dayComments] in Object.entries(groupedComments)"
            :key="dateString"
            :dateString="dateString"
          >
            <ChatRoomComment
              v-for="comment in dayComments"
              :key="comment.id"
              :roomName="chatStore.roomName"
              :comment
              :isThread
            />
          </ChatRoomDay>
          <Typing />
        </ul>
      </InfiniteScroller>
    </div>
    <Warning :scrollArea :isThread />
    <ChatRoomEditor
      class="m-2.5 @lg:m-5 !mt-0"
      :isThread
      @send="sendComment"
      @isTyping="chatStore.sendTyping($event)"
    />
  </section>
</template>
