<script lang="ts" setup>
const { scrollArea, isThread } = defineProps<{
  scrollArea: HTMLElement | null;
  isThread: boolean;
}>();
const warning = ref(false);

function showWarning() {
  if (!scrollArea) return;
  warning.value = true;
  scrollArea.addEventListener("scroll", tHideWarning);
}

function hideWarning() {
  if (!scrollArea) return;
  warning.value = false;
  scrollArea.removeEventListener("scroll", tHideWarning);
}

const chatStore = useChatStore();
const commentsLength = computed(() => chatStore.getComments(isThread).length);

watch(commentsLength, () => {
  nextTick(() => {
    if (scrollArea && scrollArea.scrollTop + scrollArea.clientHeight < scrollArea.scrollHeight - 100) {
      // if user has scrolled up and is not close to the bottom, show warning
      showWarning();
      return;
    }
  });
});

// const dOnScroll = useDebounce(updateValues, 100);
const tHideWarning = useThrottle(hideWarning, 50);

//  listener function controlling the warning for new messages
function scrollDown() {
  if (!scrollArea) return;
  scrollArea.scrollTo({ top: scrollArea.scrollHeight, behavior: "smooth" });
  warning.value = false;
  scrollArea.removeEventListener("scroll", tHideWarning);
}
</script>

<template>
  <div v-if="warning" class="py-10 -mt-[114px] flex justify-center z-30">
    <BasicButton
      id="warning-button"
      variant="white"
      size="sm"
      class="bg-neutral-0 rounded-lg p-4"
      icon="lucide-arrow-down"
      @click="scrollDown"
    >
      {{ $t("channel.latest") }}
    </BasicButton>
  </div>
</template>
