import type { GetUserByNameQueryVariables } from "#gql";

import { useQuery } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling: GqlErrorHandlingOptions;
};
export default function useUserGetByName(vars: GetUserByNameQueryVariables, options?: Options) {
  async function queryFn() {
    const res = await GqlGetUserByName({ name: vars.name });
    return res.user[0];
  }

  const context = useQuery({
    queryKey: [QUERY_KEYS.user, vars.name],
    queryFn
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);

  return context;
}
