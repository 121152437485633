<script setup lang="ts">
import EmojiPicker, { type EmojiExt } from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

defineProps<{
  tooltip: string;
}>();

const isOpen = ref(false);
function toggleOpen() {
  isOpen.value = !isOpen.value;
  emits("open", isOpen.value);
}
function setOpen(state: boolean) {
  isOpen.value = state;
}

defineExpose({
  close: () => setOpen(false)
});

const emits = defineEmits<{
  (event: "select", emoji: EmojiExt): void;
  (event: "open", open: boolean): void;
}>();

function onMouseLeave() {
  setOpen(false);
  emits("open", false);
}

function onSelect(emoji: EmojiExt) {
  setOpen(false);
  emits("select", emoji);
}
</script>

<template>
  <ClientOnly>
    <PopoverRoot :open="isOpen">
      <PopoverTrigger asChild>
        <BasicButton
          variant="toolbar"
          size="sm"
          icon="lucide-smile-plus"
          :tooltip="tooltip"
          class="relative"
          @click="toggleOpen"
        />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          side="bottom"
          class="z-50 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
          :sideOffset="5"
          @mouseleave="onMouseLeave"
        >
          <EmojiPicker
            class=""
            :native="true"
            tooltip=""
            :groupNames="{
              default: $t('channel.reaction.groupnames.default'),
              smileys_people: $t('channel.reaction.groupnames.smileys_people'),
              animals_nature: $t('channel.reaction.groupnames.animals_nature'),
              food_drink: $t('channel.reaction.groupnames.food_drink'),
              activities: $t('channel.reaction.groupnames.activities'),
              travel_places: $t('channel.reaction.groupnames.travel_places'),
              objects: $t('channel.reaction.groupnames.objects'),
              symbols: $t('channel.reaction.groupnames.symbols'),
              flags: $t('channel.reaction.groupnames.flags')
            }"
            :staticTexts="{
              placeholder: $t('channel.reaction.search'),
              skinTone: $t('channel.reaction.skintone')
            }"
            disableSkinTones
            :additionalGroups="{
              default: [
                { n: ['thumbs up sign', 'thumbsup'], u: '1f44d' },
                { n: ['thumbs down sign', 'thumbsdown'], u: '1f44e' },
                { n: ['heavy black heart', 'heart'], u: '2764-fe0f' },
                { n: ['grinning face', 'grinning'], u: '1f600' },
                { n: ['face with tears of joy', 'joy'], u: '1f602' }
              ]
            }"
            @select="onSelect"
          />
          <PopoverArrow class="fill-neutral-0" />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  </ClientOnly>
</template>
