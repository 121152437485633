<script lang="ts" setup>
import ReactionButton from "./ReactionButton.vue";
import type { Comment } from "~/types";

const { comment } = defineProps<{
  comment: Comment;
}>();

const reactions = computed(() => {
  if (!comment.userReactions) return [];
  const grouped = comment.userReactions.reduce(
    (acc, { emoji, user, pending }) => {
      if (!acc[emoji]) {
        acc[emoji] = [{ username: user.username, pending }];
      } else {
        acc[emoji].push({ username: user.username, pending });
      }
      return acc;
    },
    {} as Record<string, { username: string; pending: boolean }[]>
  );
  const entries = Object.entries(grouped);
  entries.sort((a, b) => (a[0] > b[0] ? 1 : -1));
  return entries;
});
</script>

<template>
  <ul class="flex gap-1 px-0.5">
    <ReactionButton v-for="[emoji, users] in reactions" :key="emoji" :emoji :users :commentId="comment.id" />
  </ul>
</template>
