import type { Comment } from "~/types";

export default function useGroupComments(isThread: boolean) {
  const chatStore = useChatStore();

  return computed(() => {
    return chatStore.getComments(isThread).reduce(
      (days, comment) => {
        if (!days[comment.createdAtDay]) {
          days[comment.createdAtDay] = [];
        }
        days[comment.createdAtDay].push(comment);
        return days;
      },
      {} as Record<string, Comment[]>
    );
  });
}
