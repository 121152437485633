<script setup lang="ts">
import { Icon } from "@iconify/vue";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor, EditorContent } from "@tiptap/vue-3";

import Toolbar2 from "./Toolbar2.vue";
import useIsTyping from "./useIsTyping";
import TIP_TAP_EXTENSIONS from "~/utils/tipTap";

const { content, sendOnEnter = true } = defineProps<{
  isThread?: boolean;
  content?: string;
  sendOnEnter?: boolean;
  class?: string;
}>();

const emits = defineEmits<{
  (event: "send", content: string): void;
  (event: "cancel"): void;
  (event: "isTyping", isTyping: boolean): void;
}>();

const chatStore = useChatStore();

const { t } = useI18n();

const isTyping = useIsTyping(emits);

const editor = useEditor({
  content: content && JSON.parse(content),
  extensions: [
    ...TIP_TAP_EXTENSIONS,
    Placeholder.configure({
      placeholder: t("editor.placeholder")
    })
  ],
  editorProps: {
    attributes: {
      class: "content"
    },
    handleDOMEvents: {
      click: (_, event) => {
        event.stopImmediatePropagation();
      },
      keydown: (_, event) => {
        isTyping(event);
        if (event.key === "Enter") {
          if (!event.shiftKey) {
            if (
              editor.value &&
              !editor.value.isActive("bulletList") &&
              !editor.value.isActive("orderedList") &&
              sendOnEnter
            ) {
              event.preventDefault();
              const isEmpty = !editor.value?.state.doc.textContent.trim().length;
              if (isEmpty) return;
              sendComment();
            }
          }
          if (
            event.shiftKey &&
            (editor.value?.isActive("orderedList") || editor.value?.isActive("bulletList")) &&
            sendOnEnter
          ) {
            event.preventDefault();
            sendComment();
          }
        }
      }
    }
  }
});
const editorIsEmpty = computed(() => !editor.value?.state.doc.textContent.trim().length);

function sendComment() {
  emits("send", JSON.stringify(editor.value?.getJSON()));
  editor.value?.commands.clearContent(true);
  editor.value?.commands.focus();
}

function cancelComment() {
  emits("cancel");
  editor.value?.commands.clearContent(true);
  editor.value?.commands.focus();
}

function handleClick() {
  editor.value?.commands.focus("end");
}
onBeforeUnmount(() => {
  editor.value?.destroy();
});
</script>

<template>
  <div
    :class="
      cn(
        'relative z-10 flex flex-col justify-self-stretch shrink-0 max-h-[calc(100vh-10rem)]',
        'rounded-lg bg-neutral-0 transition',
        'border border-neutral-2 focus-within',
        { 'pointer-events-none': chatStore.isLoadingRoom },
        $props.class
      )
    "
    @click="handleClick"
  >
    <EditorToolbar :editor :isThread class="p-1 border-b" />
    <div class="grow scroll-area relative max-w-2xl">
      <p
        v-if="chatStore.isLoadingRoom"
        :class="
          cn(
            'absolute top-0 flex items-center gap-1.5 p-2.5 text-neutral-5'
            // 'opacity-0 delay-500 animate-fade-in'
          )
        "
      >
        <Icon icon="lucide:loader" class="animate-spin size-4" />
        {{ t("editor.loading") }}
      </p>
      <editor-content
        :editor
        :class="
          cn('[&>div]:ring-0 grow p-2.5 transition-opacity', {
            'opacity-0': chatStore.isLoadingRoom
          })
        "
      />
    </div>
    <Toolbar2 :editorIsEmpty :editor @send="sendComment" @cancel="cancelComment" />
  </div>
</template>
