<script setup lang="ts">
import EmojiButton from "./EmojiButton.vue";
import useCommentOptions from "./useCommentOptions";
import type { Comment } from "~/types";

const { comment, isThread } = defineProps<{
  comment: Comment;
  showUser?: boolean;
  isThread?: boolean;
}>();

const { moderator } = useHasAccess();
const chatStore = useChatStore();

defineEmits<{
  (event: "editComment"): void;
}>();

const emojiPickerRef = ref();

const keepOpened = ref(false);

function onToggleEmojiPicker(open: boolean) {
  keepOpened.value = open;
}

const { isEditable, isDeletable } = useCommentOptions(comment);
</script>

<template>
  <ToolbarRoot
    :class="
      cn(
        'absolute z-10 right-0 top-0',
        'h-[38px] flex gap-1 p-0.5 w-max rounded-lg bg-neutral-0 border border-neutral-3',
        'opacity-0 group-hover:opacity-100 focus-within:opacity-100 hover:opacity-100',
        'pointer-events-none group-hover:pointer-events-auto hover:pointer-events-auto',
        {
          '!opacity-100': keepOpened,
          '-top-6': !showUser
        }
      )
    "
    aria-label="Formatting options"
  >
    <EmojiButton
      v-for="button in [
        { name: 'checked', emoji: '✅', tooltip: 'channel.reaction.completed' },
        { name: 'eyes', emoji: '👀', tooltip: 'channel.reaction.eyes' },
        { name: 'clapping hands sign', emoji: '👏', tooltip: 'channel.reaction.well-done' }
      ]"
      :key="button.name"
      v-bind="button"
      :commentId="comment.id"
    />
    <ChatRoomEmojiPicker
      ref="emojiPickerRef"
      tooltip="channel.reaction.search-other"
      @open="onToggleEmojiPicker"
      @select="chatStore.reactToComment($event.i, comment.id)"
    />
    <BasicButton
      v-if="!isThread"
      size="sm"
      variant="toolbar"
      icon="lucide-message-square-text"
      tooltip="channel.thread.open"
      @click="chatStore.switchToThread(comment.id)"
    />
    <BasicButton
      v-if="isEditable"
      size="sm"
      icon="lucide-cog"
      variant="toolbar"
      tooltip="channel.editComment"
      @click="$emit('editComment')"
    />
    <BasicButton
      v-if="isDeletable"
      size="sm"
      variant="toolbar"
      :icon="ICONS.delete"
      tooltip="channel.deleteComment"
      @confirm="chatStore.deleteMessage(comment.id)"
    />
    <BasicButton
      v-if="moderator"
      :icon="ICONS.block"
      variant="toolbar"
      size="sm"
      tooltip="channel.blockComment"
      @confirm="chatStore.blockMessage(comment.id)"
    />
  </ToolbarRoot>
</template>
