export default function useScrollPosition(scrollArea: Ref<HTMLElement | null>, isThread: boolean) {
  const chatStore = useChatStore();
  const commentsLength = computed(() => chatStore.getComments(isThread).length);

  const previousScrollHeight = ref(0);
  // initial scroll to the bottom
  // twice nextTick because once always leaves a small gap at the bottom and I can’t figure out why
  onMounted(() => {
    nextTick(() => {
      nextTick(() => {
        if (!scrollArea.value) return;
        const y = chatStore.getChatScrollTop(isThread) || scrollArea.value.scrollHeight;
        scrollArea.value.scrollTop = y;
        previousScrollHeight.value = scrollArea.value.scrollHeight;
      });
    });
  });

  watch(commentsLength, () => {
    nextTick(() => {
      if (!scrollArea.value) return;
      // don’t scroll if the user is not at the bottom (because he might want to read older comments)
      // to make it work, we compare the scroll position with the previous scroll height
      if (scrollArea.value.scrollTop + scrollArea.value.clientHeight > previousScrollHeight.value - 100) {
        scrollArea.value.scrollTo({ top: scrollArea.value.scrollHeight, behavior: "smooth" });
      }
      previousScrollHeight.value = scrollArea.value.scrollHeight;
    });
  });
}
