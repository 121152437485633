<script lang="ts" setup>
import { Icon } from "@iconify/vue";

const { emoji, users, commentId } = defineProps<{
  emoji: string;
  users: {
    username: string;
    pending: boolean;
  }[];
  commentId: string;
}>();

const chatStore = useChatStore();
const { username } = useSessionInfo();

const pending = computed(() => {
  const user = users?.find((user) => user.username === username.value);
  return user?.pending;
});
const usersList = computed(() =>
  users.reduce((acc, { username }, index) => `${acc}${index ? ", " : ""}${username}`, "")
);
</script>

<template>
  <BasicTooltip side="bottom">
    <template #trigger>
      <li class="relative">
        <Icon
          v-if="pending"
          class="absolute size-4 top-[3px] left-1.5 text-neutral-8 animate-spin"
          icon="lucide:loader"
        />
        <button
          :class="
            cn(
              'relative flex gap-1.5 items-center bg-neutral-0 border hover:border-neutral-5 transition text-sm px-1.5 rounded-full',
              {
                'opacity-50 pointer-events-none': pending
              }
            )
          "
          @click.prevent.stop="chatStore.reactToComment(emoji, commentId)"
        >
          <span class="relative top-px">{{ emoji }}</span
          ><span class="text-xs">{{ users.length }}</span>
        </button>
      </li>
    </template>
    <template #content>
      <p>{{ usersList }}</p>
    </template>
  </BasicTooltip>
</template>
